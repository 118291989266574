<template>
  <easiModal
    persistence
    :isBorder="false"
    v-if="isOpen"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <h2 class="capitalize font-medium text-3xl mt-3 text-black">Share to</h2>
    </template>
    <div class="border-t-2 pt-3 mt-3 border-boxBorder">
      <div class="w-full sm:w-11/12 mx-auto px-6 mb-6">
        <div class="w-full flex justify-between items-center gap-4 my-4">
          <div
            v-for="option in options"
            :key="option"
            @click="shareTo = option"
            :class="
              shareTo === option
                ? 'bg-newLimeGreen border-2 border-primary text-primary font-bold'
                : 'text-dark-800 border-2 border-dark-400'
            "
            class="relative cursor-pointer text-xl rounded-2xl w-6/12 px-10 py-8 flex items-center justify-center text-center"
          >
            <span class="absolute top-3 right-3 cursor-pointer">
              <img
                v-if="shareTo === option"
                src="@/assets/icons/newCheck.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/check-null.svg" alt="" />
            </span>
            <span class="">{{ option }}</span>
          </div>
        </div>

        <easiButton :loading="loading" class="mt-4" block @click="handleShare"
          >Share</easiButton
        >
      </div>
    </div>
  </easiModal>

  <easiAlert
    v-if="successModal"
    @close="successModal = false"
    :caption="`${
      type == 'payslip' ? 'Payslip' : 'Payroll'
    } information shared Successfully`"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { end } from "@popperjs/core";

const store = useDataStore();
const { query, mutate } = store;

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  input: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: "summary",
  },
});

const emit = defineEmits(["close"]);

const shareTo = ref("Admin");
const options = ref(["Admin", "Auditor"]);

const successModal = ref(false);
const loading = ref(false);

const handleShare = async () => {
  try {
    loading.value = true;
    console.log(shareTo.value);

    let payload;
    if (props.type == "payslip") {
      payload = { ...props.input };
      payload.sendEmailToAuditor = shareTo.value == "Auditor" ? true : null;
      payload.sendEmailToCompanyAdmin = shareTo.value == "Admin" ? true : null;
    } else {
      payload = {
        input: props.input,
        options: {
          sendToCompanyAdmin: shareTo.value == "Admin" ? true : null,
          sendToAuditors: shareTo.value == "Auditor" ? true : null,
        },
      };
    }

    console.log(props.input);

    const obj = {
      summary: "DownloadPayrollHistorySummary",
      detailed: "DownloadDetailedPayrollHistory",
      payslip: "SendPaySlipEmails",
    };

    const endpoint = obj[props.type];

    let res;
    if (props.type == "payslip") {
      res = await mutate({
        endpoint,
        data: {
          input: payload,
        },
        service: "PAYROLL",
      });
    } else {
      res = await query({
        endpoint,
        payload,
        service: "PAYROLL",
      });
    }

    loading.value = false;
    emit("close");
    successModal.value = true;

    setTimeout(() => {
      successModal.value = false;
    }, 3000);

    console.log(res);
  } catch (e) {
    console.error(e);
    loading.value = false;
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
